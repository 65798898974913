.marketCap {
  padding: 0.5rem;
  @include respond-to("medium") {
    padding: .5rem 1rem;
}
@include respond-to("xlarge") {
    padding: 1rem 10%;
    
}
  &__table {
    margin-bottom: 0;
    th,
    td {
      white-space: nowrap;
      vertical-align: middle;
      padding: 1rem;
      @include respond-to("small") {
        padding: 1.5rem;
      }
      @include respond-to("huge") {
        padding: 2.2rem 1.5rem;
      }
    }
    th {
      border-top: none;
      color: $grey3;
      font-size: 1.4rem;
      @include respond-to("small") {
        font-size: 1.8rem;
      }
      @include respond-to("xlarge") {
        font-size: 1.6rem;
      }
      @include respond-to("huge") {
        font-size: 2rem;
      }
    }
    td {
      color: $grey3;
      font-size: 1.3rem;
      @include respond-to("small") {
        font-size: 1.7rem;
      }
      @include respond-to("xlarge") {
        font-size: 1.4rem;
      }
      @include respond-to("huge") {
        font-size: 1.7rem;
      }
      .asset {
        display: flex;
        align-items: center;
        &__image {
          width: 3rem;
          height: 3rem;
          object-fit: contain;
          margin-right: 0.5rem;
          @include respond-to("xlarge") {
            transform: scale(1.2);
            margin-right: 1rem;
          }
        }
      }
    }
    .price {
      font-weight: bold;
      &--green {
        color: $green1;
      }
      &--red {
        color: $red1;
      }
      i {
        margin-right: 0.5rem;
      }
    }
    .stripped {
      background-color: $white2;
    }
    .numb {
      text-align: center;
    }
  }
}
