.guide {
  background-color: rgba(47, 53, 66, 0.9);
  color: white;
  text-align: center;
  position: absolute;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  top: 7rem;
  height: 88%;
  width: 95%;
  @include rounded(10px);
  @include respond-to("medium") {
    height: 89%;
  }
  @include respond-to("huge") {
    height: 70%;
  }
  &__tip {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(47, 53, 66, 0.9);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(3.3);
    top: -0.75rem;
    &.research {
      left: 14%;
      // @include respond-to("small") {}
      // @include respond-to("medium");
      // @include respond-to("large");
      // @include respond-to("xlarge");
      // @include respond-to("huge");
      // @include respond-to("ultra");
    }
    &.dev {
      left: 26%;
    }
    &.stats {
      left: 38%;
    }
    &.chart {
      left: 50%;
    }
    &.genesis {
      left: 62%;
    }
    &.block-explorer {
      left: 74%;
    }
    &.event {
      left: 86%;
    }
  }
  &__title {
    margin-top: 5rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
    font-size: 2rem;
    @include respond-to("medium") {
      font-size: 2.5rem;
      letter-spacing: 0;
    }
    @include respond-to("xlarge") {
      font-size: 2.2rem;
    }
    @include respond-to("huge") {
      font-size: 3rem;
    }
    &.end {
      margin-top: -3rem;
      @include respond-to("medium") {
        margin-top: 0;
      }
    }
    img {
      height: 2.5rem;
      width: 2.5rem;
      object-fit: contain;
      margin-left: 1rem;
    }
  }
  &__icons {
    margin: 4rem 0 3rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__icon {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
    @include respond-to("medium") {
      flex-basis: 35%;
      justify-content: flex-start;
      padding-left: 5%;
    }
    @include respond-to("huge") {
      padding-left: 7%;
    }
    img {
      height: 4rem;
      width: 4rem;
      margin-right: 1rem;
      @include respond-to("xlarge") {
        width: 3rem;
        height: 3rem;
      }
      @include respond-to("huge") {
        width: 4rem;
        height: 4rem;
      }
    }
    p {
      font-size: 2rem;
      font-weight: bold;
      @include respond-to("xlarge") {
        font-size: 1.7rem;
      }
      @include respond-to("huge") {
        font-size: 2rem;
      }
    }
  }
  &.content {
    width: 98%;
    height: 75rem;
    z-index: 102;
    @include respond-to("xlarge") {
      height: 92%;
    }
    @include respond-to("huge") {
      height: 93.5%;
    }
  }
  &.navbarCrypto {
    width: 90%;
    padding: 0 .5rem;
    height: 30rem;
    margin-top: 1.5rem;
    @include respond-to("xlarge") {
      width: 55%;
    }
    @include respond-to("huge") {
      width: 55%;
      margin-top: 4rem;
      height: 35rem;
    }
  }
  &__tag {
    margin: 4rem 0;
    font-size: 1.8rem;
    @include respond-to("xlarge") {
      font-size: 2.2rem;
    }
  }
  &__btn {
    padding: .8rem 2.5rem;
    font-family: inherit;
    font-size: 1.3rem;
    font-weight: bold;
    @include rounded(5px);
    &.content {
      margin-top: 1rem;
    }
  }
}
