*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  @include respond-to('small') {
    font-size: 63%;
  }
  @include respond-to('medium') {
    font-size: 70%;
  }
  @include respond-to('large') {
    font-size: 75%;
  }
  @include respond-to('xlarge') {
    font-size: 80%;
  }
  @include respond-to('huge') {
    font-size: 85%;
  }
  @include respond-to('huge') {
    font-size: 70%;
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
}


/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $white3; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $white3; 
}