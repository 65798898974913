.mediaHighlightNews {
  @include respond-to("small") {
    flex-basis: 49%;
    margin-right: .6rem;
  }
  @include respond-to("huge") {
    margin-right: 1.3rem;
  }
  @include respond-to("ultra") {
    margin-right: 1.8rem;
  }
  &__content {
    height: 65rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__card {
    background-color: $white1;
    overflow: hidden;
    border: 1px solid $white3;
    &:not(:last-child) {
      margin-bottom: 1rem;
      @include respond-to("small") {
        margin-bottom: 1.2rem;
      }
      @include respond-to("huge") {
        margin-bottom: 2.3rem;
      }
      @include respond-to("ultra") {
        margin-bottom: 3rem;
      }
    }
    @include rounded(10px);
    .head {
      height: 24rem;
      background-size: cover;
      background-position: center center;
      color: $white1;
      display: flex;
      padding: 2rem;
      align-items: flex-end;
      @include shadowIn($grey4, 0.8);
      .main {
        font-size: 2.1rem;
        width: 100%;
        border-bottom: 1px solid $white1;
        padding-bottom: 0.5rem;
        @include respond-to("xlarge") {
          font-size: 1.8rem;
        }
      }
    }
    .text {
      padding: 2rem;
      .infos {
        display: flex;
        border-bottom: 1px solid $grey1;
        padding-bottom: 1rem;
        .info {
          font-size: 1.2rem;
          margin-right: 1.5rem;
          color: $grey3;
          @include respond-to("xlarge") {
            font-size: 1rem;
          }
          i {
            margin-right: 0.4rem;
            color: $blue3;
          }
        }
      }
      .news {
        text-align: justify;
        margin-top: 1rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-indent: 2rem;
        color: $grey3;
        border-bottom: 1px solid $grey1;
        padding-bottom: 2rem;
        @include respond-to("xlarge") {
          font-size: 1.2rem;
        }
      }
      .coin {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
        margin-top: 1.5rem;
        @include respond-to("xlarge") {
          transform: scale(.8);
          margin-top: 1rem;
        }
      }
    }
  }
  &__btnCont {
    margin: .5rem 0 1.2rem 0;
    text-align: center;
  }
  &__btn {
    font-size: 1.6rem;
    padding: .6rem 1.8rem;
    background-color: $grey4;
    @include respond-to("xlarge") {
      font-size: 1.3rem;
      margin-top: 1rem;
    }
    @include respond-to("huge") {
      font-size: 1.8rem;
    }
    &:hover {
      background-color: $grey3;
    }
    &:active {
      background-color: $grey2 !important;
    }
  }
}
