.research {
  &Summary {
    &__cont {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
    &__head {
      font-weight: 700;
      text-transform: capitalize;
      font-size: 2rem;
      color: $grey3;
      margin-bottom: 0.8rem;
      @include respond-to("huge") {
        font-size: 2.5rem;
      }
    }
    &__desc {
      text-align: justify;
      text-indent: 2rem;
      font-size: 1.2rem;
      color: $grey2;
      @include respond-to("huge") {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
    }
    &__links {
      display: flex;
      flex-wrap: wrap;
      @include respond-to("xlarge") {
        justify-content: space-between;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      @include respond-to("xlarge") {
        flex-basis: 30%;
        &:nth-child(n+4) {
          margin-top: 2.5rem;
        }
      }
      &:not(:last-child) {
        margin-bottom: 2rem;
        @include respond-to("xlarge") {
          margin-bottom: 0;
        }
      }
      .coinImg {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
        margin-right: 0.8rem;
        @include respond-to("huge") {
          transform: scale(1.1);
          margin-right: 1.2rem;
        }
      }
      .link {
        font-size: 1.5rem;
        @include respond-to("huge") {
          font-size: 1.8rem;
        }
      }
    }
  }
}
