.mt {
  &--sm {
    margin-top: 1.5rem;
  }
  &--reg {
    margin-top: 2rem;
  }
}

.mb {
  &--sm {
    margin-bottom: 1.5rem;
    &--lastCard {
      margin-bottom: 0;
      @include respond-to("xlarge") {
        margin-bottom: 1.5rem;
      }
    }
  }
  &--reg {
    margin-bottom: 2rem;
  }
}
