.event {
  &__head {
    font-size: 1.5rem;
    font-weight: 700;
    color: $grey3;
  }
  &__twitter {
      overflow-y: scroll;
      margin-top: .5rem;
      height: 50rem;
  }
}
