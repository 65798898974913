.gen {
  &__chartCont {
    width: 100%;
    height: 20rem;
  }
  &__chart {
    padding-top: 1rem;
    margin-left: -1.8rem;
    &Head {
      text-align: center;
      font-size: 1.7rem;
      font-weight: 700;
      color: $grey3;
      margin-bottom: 0.5rem;
    }
  }
  &__infos {
    display: flex;
    flex-wrap: wrap;
  }
  &__info {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .title {
      font-weight: 700;
      color: $grey3;
    }
    .result {
      color: $grey2;
    }
  }
  &__linkCont {
      display: flex;
      flex-direction: column;
  }
  &__link {
      color: $grey3;
      font-size: 1.3rem;
      &:not(:last-child) {
          margin-bottom: .5rem;
      }
  }
}
