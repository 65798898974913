.chart {
    &__candle {
        height: 400px;
        width: 55px;
        background-color: red;
    }
}

.react-stockchart {
    width: 100% !important;
    height: 600px !important;
}