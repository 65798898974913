.exp {
  &__searchGroup {
    position: relative;
  }
  &__search,
  &__select {
    width: 100%;
    font-size: 1.7rem;
    border: none;
    background: none;
    color: $grey3;
    font-weight: 700;
    &:focus {
      outline: none;
    }
  }
  &__icon {
    font-size: 1.7rem;
    color: $grey3;
    position: absolute;
    @include centerV;
    &--select {
      right: 1.5rem;
    }
    &--search {
      left: 1.5rem;
    }
  }
  &__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0rem 3rem 0rem 1.5rem;
    &::-ms-expand {
      display: none;
    }
  }
  &__search {
    padding: 0rem 2rem 0rem 4rem;
  }
  &__card {
    padding: 0;
    overflow: hidden;
    .head {
      text-transform: uppercase;
      text-align: center;
      background-color: $grey3;
      color: $white1;
      font-size: 1.5rem;
      font-weight: 700;
      padding: 0.5rem;
    }
    .text {
      margin: 1rem;
    }
  }
  &Summary {
    &__head {
      padding: 2rem 5%;
    }
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.4rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .title {
        font-weight: 700;
        color: $grey3;
      }
      .result {
        color: $grey2;
        overflow-wrap: break-word;
        &--final {
          background-color: $orange2;
          padding: 0.3rem 1rem;
          color: $white1;
          @include rounded(5px);
        }
        &--orange {
            color: $orange3;
        }
        &--hash {
            text-align: right;
            max-width: 20rem;
        }
      }
    }
    &__trans {
      padding: 1rem 0;
      .transHead {
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
        color: $grey3;
      }
      .hLine {
        height: 2px;
        width: 100%;
        background-color: $grey2;
        margin: 0.5rem 0 2rem 0;
      }
      &--trans {
          padding: 0 0 1rem 0;
      }
    }
  }
  &__addressCard {
    padding: 0;
    overflow: hidden;
    @include rounded(5px);
    @include shadow($white3, 0.5);
  }
  &__table {
    &Cont {
      display: flex;
      flex-wrap: wrap;
      .input,
      .output {
          display: flex;
          flex-direction: column;
        flex-basis: 50%;
        &Head {
          text-align: center;
          font-size: 1.4rem;
          padding: 0.4rem;
          text-transform: uppercase;
          font-weight: 700;
          color: $white1;
        }
      }
      .input {
        &Head {
          background-color: $blue2;
        }
      }
      .output {
        &Head {
          background-color: $green2;
        }
      }
    }
    &Rec {
        padding: 1rem;
        .rec {
            text-transform: capitalize;
            font-size: 1.3rem;
            font-weight: 700;
            color: $grey3;
        }
        .result {
            font-size: 1.1rem;
            color: $grey2;
            width: 15rem;
            overflow-wrap: break-word;
        }
    }
    &Value {
        flex-basis: 50%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        background-color: $white3;
        .title {
            color: $grey3;
            font-weight: 700;
            text-transform: uppercase;
        }
        .result {
            color: $grey2;
        }
    }
  }
}
