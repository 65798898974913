.analysisPage {
  &__nav {
    // background-color: skyblue;
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .divider {
      height: 2rem;
      width: 2px;
      background-color: $grey3;
      margin: 0 1rem;
      @media only screen and (min-width: 375px) {
        margin: 0 1.4rem;
      }
      @include respond-to("small") {
        margin: 0 3rem;
      }
      @include respond-to("large") {
        margin: 0 4rem;
      }
      @include respond-to("xlarge") {
        margin: 0 2.2rem;
      }
      @include respond-to("huge") {
        margin: 0 3.5rem;
      }
      @include respond-to("ultra") {
        margin: 0 5rem;
      }
    }
    &Icon {
      cursor: pointer;
      transition: all 0.2s ease-out;
      // background-color: red;
      text-align: center;
      position: relative;
      &:hover {
        margin-top: -0.7rem;
      }
      &:active {
        margin-top: 0rem;
      }
      @include respond-to("medium") {
        width: 3rem;
      }
      @include respond-to("xlarge") {
        width: 3.5rem;
      }
      img {
        height: 2.2rem;
        width: 2.2rem;
        object-fit: contain;
        @media only screen and (min-width: 375px) {
          transform: scale(1.2);
        }
        @include respond-to("small") {
          transform: scale(1.6);
        }
        @include respond-to("xlarge") {
          transform: scale(1.2);
        }
        @include respond-to("huge") {
          transform: scale(1.5);
        }
      }
      .active {
        height: 3px;
        width: 100%;
        background-color: black;
        position: absolute;
        bottom: -1rem;
        @include respond-to("xlarge") {
          bottom: -1.2rem;
        }
        @include respond-to("huge") {
          bottom: -1.4rem;
        }
      }
    }
  }
}
