@import "./abstracts/mixins";
@import "./abstracts/variables";

@import "./base/base";
@import "./base/typography";
@import "./base/utilities";

@import "./components/button";
@import "./components/card";
@import "./components/dropdown";
@import "./components/input";
@import "./components/loader";

@import "./layout/footer";
@import "./layout/navBar";
@import "./layout/navBarCrypto";

@import "./pages/analysisPage/analysisPage";
@import "./pages/auth/signInPage";
@import "./pages/mediaPage/mediaPage";
@import "./pages/marketCap/marketCap";

@import "./vendors/carousel";

.page {
  padding: 0.8rem 0.5rem;
  @include respond-to("xlarge") {
    padding: 0.5rem 0;
  }
}
