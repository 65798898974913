.news {
  &__cont {
    margin-top: 1rem;
    height: 50rem;
    overflow-y: scroll;
    position: relative;
    @include respond-to("small") {
      height: 54rem;
    }
    @include respond-to("xlarge") {
      height: 85.5rem;
    }
  }
  &__card {
    width: 100%;
    height: 20rem;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    position: relative;
    margin-bottom: 1.5rem;
    @include rounded(10px);
    @include shadowIn($grey4, 0.7);
    &:hover,
    &:focus {
      outline: none !important;
    }
    @include respond-to("xlarge") {
      height: 15rem;
      margin-bottom: 0.5rem;
    }
    @include respond-to("huge") {
      height: 18rem;
      margin-bottom: 1rem;
    }
    .title {
      color: $white1;
      font-size: 1.7rem;
      position: absolute;
      bottom: 1rem;
      text-align: justify;
      margin-right: 1.5rem;
      @include respond-to("xlarge") {
        font-size: 1.2rem;
        bottom: 0.5rem;
        left: 1rem;
        right: 1rem;
        margin-right: 0;
      }
      @include respond-to("huge") {
        font-size: 1.7rem;
      }
    }
  }
  &-item {
    &:hover,
    &:focus {
      outline: none !important;
    }
  }
  &__overlay {
    display: none;
    background-color: rgba(52, 73, 94, 0.9);
    // background-color: black;
    padding: 1.5rem;
    text-align: justify;
    text-indent: 1.5rem;
    width: 100%;
    height: 20rem;
    position: relative;
    z-index: 1;
    overflow-y: scroll;
    color: white;
    @include respond-to("xlarge") {
      height: 15rem;
    }
    @include respond-to("huge") {
      height: 18rem;
    }
    p {
      font-size: 1.3rem;
      line-height: 2.5rem;
    }
    .link {
      margin-top: -0.5rem;
      text-align: right;
      font-size: 1.6rem;
      a {
        color: white;
        font-weight: bold;
      }
    }
    &.active {
      display: block;
    }
  }
  
}
