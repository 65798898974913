.dev {
  &__chartCont {
    width: 100%;
    height: 20rem;
  }
  &__chart {
    padding-top: 1rem;
    margin-left: -1.8rem;
  }
  &__head {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: $grey3;
    @include respond-to("huge") {
      font-size: 2.5rem;
      margin: 3rem 0 2rem 0;
    }
  }
  &__cardCont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__card {
    overflow: hidden;
    padding: 0;
    flex-basis: 31%;
    &:nth-child(n+1):nth-child(-n+3) {
      margin-bottom: 1.5rem;
    }
    @include rounded(5px);
    .visual {
      width: 100%;
      height: 10rem;
      object-fit: cover;
      @include respond-to("small") {
        height: 18rem;
      }
      @include respond-to("xlarge") {
        height: 25rem;
      }
    }
    .text {
      padding: 1rem;
      @include respond-to("huge") {
        padding: 1.5rem 2rem;
      }
      .name {
        font-weight: 700;
        color: $grey3;
        font-size: 1.5rem;
        @include respond-to("huge") {
          font-size: 2.3rem;
        }
      }
      .role {
        color: $grey2;
        font-size: 1.1rem;
        @include respond-to("huge") {
          font-size: 1.5rem;
        }
      }
    }
  }
}
