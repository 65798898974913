.mediaHighlightSocial {
  @include respond-to("small") {
    flex-basis: 49%;
    order: 2;
    margin-left: 0.6rem;
  }
  @include respond-to("huge") {
    margin-left: 1.3rem;
  }
  @include respond-to("ultra") {
    margin-left: 1.8rem;
  }
  &__btns {
    height: 4rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey2;

    i {
      cursor: pointer;
      font-size: 2.4rem;
      color: $grey3;
      margin-right: 1.3rem;
      @include respond-to("xlarge") {
        margin-right: 1.6rem;
        font-size: 2.2rem;
      }
    }
    .youtube {
      i {
        &:hover {
          color: $red3;
        }
        &:active {
          color: $red2;
        }
      }
      &.active {
        i {
          color: $red3;
        }
      }
    }
    .twitter {
      i {
        &:hover {
          color: $blue2;
        }
        &:active {
          color: $blue1;
        }
      }
      &.active {
        i {
          color: $blue2;
        }
      }
    }
    .instagram {
      i {
        &:hover {
          color: $pink2;
        }
        &:active {
          color: $pink1;
        }
      }
      &.active {
        i {
          color: $pink2;
        }
      }
    }
  }
  &__content {
    margin: 1rem 0 0 0;
    height: 60rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__card {
    &--youtubeCont {
      @include respond-to("xlarge") {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    &--youtube {
      background-color: $white1;
      border: 1px solid $white3;
      overflow: hidden;
      margin-bottom: 1.5rem;
      @include rounded(10px);
      @include respond-to("xlarge") {
        flex-basis: 49%;
      }
      .embed-responsive {
        height: 18rem;
        @include respond-to("huge") {
          height: 25rem;
        }
      }
      .text {
        padding: 1rem 1.5rem;
        .main {
          font-size: 1.6rem;
          color: $grey3;
          font-weight: 700;
          @include respond-to("xlarge") {
            font-size: 1.3rem;
          }
        }
        .creator,
        .time {
          margin-top: 0.3rem;
          font-size: 1.3rem;
          @include respond-to("xlarge") {
            font-size: 1.1rem;
          }
          i {
            color: $red3;
            margin-right: 0.5rem;
          }
        }
      }
      &Analysis {
        flex-basis: 100%;
      }
    }
  }
}
