.navBarCrypto {
  padding: 1.5rem 2rem;
  background-color: $white2;
  position: relative;
  @include respond-to("small") {
    padding: 2rem;
  }
  @include respond-to("medium") {
    padding: 2.5rem;
  }
  @include respond-to("large") {
    padding: 2.5rem 3rem;
  }
  @include respond-to("xlarge") {
    padding: 1rem .3rem 1.8rem .3rem;
  }
  &__mobile {
    @include respond-to("xlarge") {
      display: none;
    }
  }
  &__desktop {
    display: none;
    @include respond-to("xlarge") {
      display: inline;
    }
  }
  &__lead {
    display: flex;
    position: relative;
    img {
      height: 3.5rem;
      width: 3.5rem;
      object-fit: contain;
      @include respond-to("small") {
        transform: scale(1.3);
      }
      @include respond-to("large") {
        transform: scale(1.5);
      }
    }
    &text {
      @include centerV;
      left: 5.3rem;
      @include respond-to("small") {
        left: 5.8rem;
      }
      @include respond-to("large") {
        left: 6rem;
      }
      .main {
        font-size: 1.9rem;
        color: $grey4;
        @include respond-to("small") {
          font-size: 2.2rem;
        }
        @include respond-to("large") {
          font-size: 2.5rem;
        }
        img {
          width: 2rem;
          height: auto;
          object-fit: contain;
          margin-left: 0.5rem;
          margin-bottom: 0.2rem;
          @include respond-to("large") {
            margin-left: 1rem;
          }
        }
      }
      .price {
        margin-top: -0.9rem;
        font-size: 1.7rem;
        color: $grey3;
        @include respond-to("small") {
          font-size: 2rem;
        }
        @include respond-to("large") {
          font-size: 2.2rem;
        }
      }
    }
    
  }
  &__btncont {
    position: relative;
  }
  &__btn {
    @include centerV;
    right: 1.5rem;
    font-size: 1.3rem;
    padding: 0.6rem 1rem;
    @include respond-to("small") {
      font-size: 1.5rem;
    }
    @include respond-to("large") {
      font-size: 1.8rem;
    }
  }
  &__coins {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  &__coin {
    margin: 1rem 0;
    text-align: center;
    padding: 1rem 0;
    @include rounded(10px);
    .name {
      font-size: 1.1rem;
      color: $grey4;
      @include respond-to("small") {
        font-size: 1.5rem;
      }
      @include respond-to("large") {
        font-size: 1.8rem;
      }
    }
    .price {
      margin-top: -0.1rem;
      margin-bottom: 0.4rem;
      font-size: 1.2rem;
      color: $grey3;
      @include respond-to("small") {
        font-size: 1.4rem;
      }
      @include respond-to("large") {
        font-size: 1.6rem;
      }
    }
    img {
      height: 2.3rem;
      width: 2.3rem;
      object-fit: contain;
      @include respond-to("small") {
        transform: scale(1.2);
        margin-top: 0.5rem;
      }
      @include respond-to("large") {
        transform: scale(1.3);
      }
    }
    &.active {
      @include shadow(black, .1);
    }
  }
  &__cont {
    text-align: center;
    display: flex;
  }
  &__coindesktop {
    cursor: pointer;
    // width: 5.18vw;
    width: 4.8vw;
    transition: all .2s ease-out;
    padding: 1.2rem 0;
    @include rounded(10px);
    &:hover {
      transform: translateY(-.3rem);
    }
    &:active {
      transform: translateY(.4rem);
    }
    .name {
      font-size: 1rem;
      color: $grey4;
      @include respond-to("xlarge") {
        font-size: .9rem;
      }
      @include respond-to("huge") {
        font-size: 1.6rem;
      }
    }
    .price {
      color: $grey3;
      font-size: 1rem;
      margin-top: 0.2rem;
      margin-bottom: 0.3rem;
      @include respond-to("xlarge") {
        font-size: .9rem;
      }
      @include respond-to("huge") {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
      }
    }
    img {
      width: 2.2rem;
      height: 2.2rem;
      object-fit: contain;
      @include respond-to("huge") {
        transform: scale(1.2);
      }
    }
    &.active {
      @include shadow(black, .08);
    }
  }
  &__divider {
    width: .5vw;
    position: relative;
    @include respond-to("xlarge") {
      width: 1vw;
    }
  }
  &__line {
    width: 2px;
    height: 2.3rem;
    background-color: $grey2;
    @include centerVH;
    @include respond-to("ultra") {
      width: 2px;
      @include rounded(20px);
      height: 3rem;
    }
  }
  &__leaddesk {
    width: 12vw;
    position: relative;
    @include respond-to("huge") {
      width: 10vw;
    }
    &coin {
      display: flex;
      @include centerVH;
      &.active {
        padding: .8rem .5rem;
        @include shadow(black, .1);
        @include rounded(8px);
        @media (min-width: 1440px) {
          padding: 1rem;
        }
        @media (min-width: 1920px) {
          padding: 1.2rem 1.5rem;
        }
      }
    }
    &text {
      text-align: left;
      margin-left: 0.3rem;
      @include respond-to("huge") {
        margin-left: 1rem;
      }
      .name {
        font-size: 1.6rem;
        color: $grey4;
        @include respond-to("huge") {
          font-size: 2.3rem;
        }
        img {
          height: 1.6rem;
          width: 1.6rem;
          object-fit: contain;
          @include respond-to("huge") {
            transform: scale(1.2);
            margin-top: -0.5rem;
          }
        }
      }
      .price {
        font-size: 1.3rem;
        margin-top: -0.4rem;
        color: $grey3;
        @include respond-to("huge") {
          font-size: 1.8rem;
          margin-top: -1rem;
        }
      }
    }
    img {
      height: 3.5rem;
      width: 3.5rem;
      object-fit: contain;
      @include respond-to("huge") {
        transform: scale(1.4);
        margin-top: 0.5rem;
      }
    }
  }
}
