.tools {
  padding: 1.5rem 2rem;
  @include respond-to("medium") {
    padding: 1.5rem 1rem;
  }
  &__card {
    background-color: $white1;
    padding: 1.3rem 1.5rem;
    @include rounded(10px);
    @include shadow($grey3, 0.1);
  }
  &Head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &__img {
      // background-color: red;
      flex-basis: 12%;
      width: 3.5rem;
      height: 3.5rem;
      object-fit: contain;
      @include respond-to("small") {
        flex-basis: 8%;
      }
      @include respond-to("xlarge") {
        transform: scale(0.9);
      }
      @include respond-to("huge") {
        transform: scale(1);
        flex-basis: 6%;
      }
      @include respond-to("ultra") {
        flex-basis: 4%;
      }
    }
    &__text {
      // background-color: green;
      flex-basis: 50%;
      @include respond-to("huge") {
        flex-basis: 20%;
      }
      @include respond-to("ultra") {
        flex-basis: 15%;
      }
      .main {
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 2.3rem;
        margin-bottom: .7rem;
        @include respond-to("xlarge") {
          font-size: 1.8rem;
        }
        @include respond-to("huge") {
          font-size: 2rem;
        }
      }
      .sub {
        font-size: 1.2rem;
        margin-top: -0.8rem;
        @include respond-to("xlarge") {
          font-size: 1rem;
          margin-top: -0.4rem;
        }
        @include respond-to("huge") {
          font-size: 1.3rem;
        }
      }
    }
    &__title {
      // background-color: tomato;
      flex-basis: 33%;
      text-align: center;
      font-size: 1.5rem;
      text-transform: capitalize;
      @include respond-to("small") {
        flex-basis: 42%;
        font-size: 2rem;
      }
      @include respond-to("xlarge") {
        font-size: 1.8rem;
      }
      @include respond-to("huge") {
        flex-basis: 69%;
        font-size: 2.2rem;
      }
      @include respond-to("ultra") {
        flex-basis: 76%;
        font-size: 2.2rem;
      }
    }
    &__icon {
      // background-color: blue;
      flex-basis: 10%;
      text-align: center;
      font-size: 2rem;
      color: $grey3;
      @include respond-to("small") {
        font-size: 2.4rem;
      }
      @include respond-to("xlarge") {
        font-size: 2rem;
        cursor: pointer;
        transition: all .5s ease-out;
        &:hover {
          transform: scale(1.2);
        }
      }
      @include respond-to("huge") {
        flex-basis: 5%;
        font-size: 2.5rem;
      }
    }
  }
  &__slide {
    display: none;
    @include respond-to("xlarge") {
      width: 100%;
      display: inline;
    }
  }
  &__slider {
    @include rounded(10px);
    overflow: hidden;
    img {
      height: 25rem;
      object-fit: contain;
      @include respond-to("xlarge") {
        height: 36rem;
      }
      @include respond-to("huge") {
        height: 45rem;
      }
    }
    
  }
}

@import "./toolResearch";
@import "./toolDev";
@import "./toolStats";
@import "./toolChart";
@import "./toolGenesis";
@import "./toolExp";
@import "./toolEvent";
