.mediaHighlight {
  
  &__head {
    display: flex;
    align-items: center;
    .vLine {
      width: 4px;
      height: 4.5rem;
      background-color: $grey3;
      margin-right: 0.8rem;
    }
    .text {
      .main {
        font-size: 2.2rem;
        text-transform: uppercase;
        font-weight: 700;
        color: $grey3;
      }
      .sub {
        font-size: 1.6rem;
        color: $grey2;
        margin-top: -0.8rem;
      }
    }
  }
  &__content {
    margin: 1rem 0 1.5rem 0;
    @include respond-to("small") {
      margin: 1rem 0;
    }
  }
}
