.mediaHeadNews {
  height: 20rem;
  width: 50%;
  display: inline-block;
  overflow: hidden;
  padding: 0.1rem 0.3rem;
  @include rounded(23px);
  position: relative;
  @include respond-to("small") {
    height: 22rem;
  }
  @include respond-to("xlarge") {
    width: 20%;
    height: 22rem;
    &:not(:root:root) {
      margin-bottom: 0.5rem;
    }
  }
  @include respond-to("huge") {
    height: 25rem;
  }
  @include respond-to("ultra") {
    height: 27rem;
  }

  &--slide {
    width: 100%;

    @include respond-to("small") {
      height: 30rem;
    }
    @include respond-to("large") {
      height: 35rem;
    }
    @include respond-to("xlarge") {
      width: 40%;
      height: 22rem;
    }
    @include respond-to("huge") {
      height: 25rem;
    }
    @include respond-to("ultra") {
      height: 27rem;
    }
  }
  &__desktop {
    display: none;
    @include respond-to("xlarge") {
      display: inline-block;
    }
  }
  &__mobile {
    @include respond-to("xlarge") {
      display: none;
    }
  }
  &__slider {
    background-color: red;

    img {
      height: 19.7rem;
      object-fit: cover;
      @include rounded(15px);
      @include respond-to("small") {
        height: 30rem;
      }
      @include respond-to("large") {
        height: 35rem;
      }
      @include respond-to("xlarge") {
        height: 22rem;
      }
      @include respond-to("huge") {
        height: 25rem;
      }
      @include respond-to("ultra") {
        height: 27rem;
      }
    }
  }
}

//safari bug
.slider-wrapper .slide {
  @include rounded(15px);
}
