.social {
  &__cont {
    @include respond-to("small") {
      height: 55rem;
      overflow: hidden;
    }
    @include respond-to("xlarge") {
      height: 86.5rem;
    }
  }
  &__media {
    margin: 1rem 0 0 0;
    height: 80rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
