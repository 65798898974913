.page--analysis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.analysisPage {
  &__content {
    flex-basis: 100%;
    order: 1;
    position: relative;
    @include respond-to("medium") {
      flex-basis: 100%;
    }
    @include respond-to("xlarge") {
      flex-basis: 60%;
      order: 2;
    }
    @include respond-to("ultra") {
      flex-basis: 64%;
    }
  }
  &__social {
    flex-basis: 100%;
    order: 2;
    padding: 0 2rem;
    position: relative;
    @include respond-to("small") {
      flex-basis: 50%;
      order: 3;
      padding: 0 2rem 0 1rem;
    }
    @include respond-to("medium") {
      flex-basis: 50%;
      padding: 0 1rem;
    }
    @include respond-to("xlarge") {
      flex-basis: 20%;
      
    }
    @include respond-to("huge") {
      flex-basis: 20%;
    }
    @include respond-to("ultra") {
      flex-basis: 18%;
    }
  }
  &__news {
    flex-basis: 100%;
    order: 3;
    margin-top: 1.5rem;
    padding: 0 2rem;
    position: relative;
    @include respond-to("small") {
      flex-basis: 50%;
      margin-top: 0;
      order: 2;
      padding: 0 1rem 0 2rem;
    }
    @include respond-to("medium") {
      flex-basis: 50%;
      order: 1;
      padding: 0 1rem;
    }
    @include respond-to("xlarge") {
      flex-basis: 20%;
    }
    @include respond-to("huge") {
      flex-basis: 20%;
    }
    @include respond-to("ultra") {
      flex-basis: 18%;
    }
  }
  &__head {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    .vLine {
      height: 4.5rem;
      width: 4px;
      background-color: $grey3;
      margin-right: 0.8rem;
    }
    .text {
      .main {
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $grey4;
      }
      .sub {
        color: $grey3;
        text-transform: uppercase;
        font-size: 1.8rem;
        margin-top: -0.5rem;
      }
    }
  }
  &__slide {
    width: 100%;
    flex-basis: 100%;
    order: 4;
    padding: 0 2rem;
    margin: 1.5rem 0 1rem 0;
    @include respond-to("medium") {
      padding: 0 1rem;
    }
    @include respond-to("xlarge") {
      display: none;
    }
  }
  &__slider {
    @include rounded(10px);
    overflow: hidden;
    img {
      height: 25rem;
      object-fit: cover;
      @include respond-to("small") {
        height: 45rem;
        object-fit: contain;
      }
      @include respond-to("medium") {
        height: 44rem;
        
      }
      @include respond-to("large") {
        height: 55rem;
        
      }
     
    }
  }
  &__btn {
    padding: .8rem 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    width: 50%;
    margin: 1.5rem auto 2rem auto;
    display: table;
  }
}

@import "./analysisPageContent";
@import "./analysisPageNews";
@import "./analysisPageSocial";
@import "./analysisPageGuide";
@import "./tools/tools";
