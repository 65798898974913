.mediaPage {  
  &__highlight {
    padding:  0.3rem;
    margin: 1rem 0 0.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include respond-to("small") {
      flex-direction: row;
    }
  }
  &__news {
    display: flex;
    font-display: column;
    flex-wrap: wrap;
    justify-content: center;
    .outside {
      flex-basis: 100%;
      @include respond-to("small") {
        flex-basis: 49%;
        &:nth-child(odd) {
          margin-right: 0.6rem;
        }
        &:nth-child(even) {
          margin-left: 0.6rem;
        }
      }
      @include respond-to("huge") {
        flex-basis: 49%;
        &:nth-child(odd) {
          margin-right: 1.3rem;
        }
        &:nth-child(even) {
          margin-left: 1.3rem;
        }
      }
      @include respond-to("ultra") {
        flex-basis: 49%;
        &:nth-child(odd) {
          margin-right: 1.8rem;
        }
        &:nth-child(even) {
          margin-left: 1.8rem;
        }
      }
    }
  }
}
@import "./mediaHeadNews";
@import "./mediaHeadNewsCard";
@import "./mediaHighlight";
@import "./mediaHighlightSocial";
@import "./mediaHighlightNews";
