.signIn {
  background-image: url("https://media.idownloadblog.com/wp-content/uploads/2016/02/Firewatch-Wallpaper-desktop-2560x1440-yellow.jpg");
  background-size: cover;
  background-position: center center;
  height: 100vh;
  &__card {
    @include centerVH;
    width: 85%;
    padding: 2rem;
    @include rounded(5px);
    overflow: hidden;
    @include respond-to('small') {
      width: 50%;
    }
    @include respond-to('large') {
      width: 40%;
    }
    @include respond-to('xlarge') {
      width: 30%;
    }
    @include respond-to('huge') {
      width: 25%;
    }
    @include respond-to('huge') {
      width: 18%;
    }
  }
  &__heading {
    font-size: 2.5rem;
  }
  &__form {
    margin-top: 1.5rem;
  }
  &__info {
    margin-top: 3rem;
  }
  &__forgot {
    font-size: 1.4rem;
    cursor: pointer;
    color: $orange3;
    &:hover {
      color: $grey2;
    }
  }
  &__btn {
    font-size: 1.6rem;
    padding: 0.6rem 1.5rem;
    &social {
        font-size: 1.3rem;
        margin: 0 .5rem;
    }
  }
  &__illust1,
  &__illust2 {
    width: 10rem;
    height: 10rem;
    position: absolute;
    opacity: 0.2;
  }
  &__illust1 {
    top: -2rem;
    right: -1rem;
  }
  &__illust2 {
    bottom: -2rem;
    left: -4.5rem;
  }
  &__register {
    margin-top: 4rem;
    font-size: 1.3rem;
    color: $grey2;
    cursor: pointer;
    @include respond-to('small') {
      margin-top: 2rem;
    }
    @include respond-to('large') {
      margin-top: 3rem;
    }
    span {
      color: $orange3;
      &:hover {
        color: $grey2;
      }
    }
  }
  &__or {
      font-size: 1.2rem;
      color: $grey2;
      margin: 1.3rem 0;
  }
}
