.stat {
  &__chartCont {
    width: 100%;
    height: 12rem;
    @include respond-to("xlarge") {
      height: 25rem;
    }
  }
  &__chart {
    padding-top: 1rem;
    margin-left: -1.8rem;
    &Head {
      text-align: center;
      font-size: 1.7rem;
      font-weight: 700;
      color: $grey3;
      margin-bottom: 0.5rem;
      @include respond-to("huge") {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__dailyCont {
    display: flex;
    flex-wrap: wrap;
    @include respond-to("xlarge") {
      justify-content: space-between;
    }
    .section {
      flex-basis: 100%;
      @include respond-to("xlarge") {
        flex-basis: 48%;
      }

      .dailyInfo {
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        .title {
          font-weight: 700;
          color: $grey3;
        }
        .result {
          color: $grey2;
        }
      }
    }
  }
}

.averageHead {
  text-align: center;
  text-transform: capitalize;
  font-size: 1.7rem;
  color: $grey3;
  font-weight: 700;
  margin-bottom: 2rem;
  @include respond-to("huge") {
    font-size: 2rem;
  }
}
