.navBar {
  background-color: #000;
  color: $white1;
  padding: 1.5rem 2rem;
  @include respond-to("small") {
    padding: 2.5rem 2rem;
  }
  @include respond-to("medium") {
    padding: 2rem;
  }
  @include respond-to("large") {
    padding: 2.8rem 2rem;
  }
  @include respond-to("huge") {
    padding: 3.5rem 2rem;
  }
  &__toggler {
    background-color: $white4;
    text-align: center;
    i {
      cursor: pointer;
      @include centerVH;
      font-size: 1.7rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        font-size: 1.9rem;
      }
      @include respond-to("small") {
        font-size: 2rem;
        &:hover {
          font-size: 2.2rem;
        }
      }
      @include respond-to("huge") {
        font-size: 2.4rem;
        &:hover {
          font-size: 2.8rem;
        }
      }
    }
  }
  &__heading {
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.8rem;
    @include respond-to("small") {
      font-size: 1.7rem;
    }
    @include respond-to("medium") {
      line-height: 2.6rem;
      font-size: 1.9rem;
    }
    @include respond-to("xlarge") {
      font-size: 2.3rem;
    }
    @include respond-to("huge") {
      font-size: 2.7rem;
    }
  }
  &__logo,
  &__logo2 {
    img {
      width: 70%;
      @include centerVH;
      @include respond-to("small") {
        height: 5rem;
        width: 9rem;
        object-fit: contain;
      }
      @include respond-to("medium") {
        height: 5rem;
        width: 10rem;
      }
      @include respond-to("huge") {
        height: 7rem;
        width: 15rem;
      }
    }
  }

  &__logo {
    img {
      @include respond-to("xlarge") {
        left: 25%;
      }
      @include respond-to("huge") {
        left: 20%;
      }
      @include respond-to("huge") {
        left: 15%;
      }
    }
  }
  &__menus {
    display: flex;
    text-transform: uppercase;
    flex-direction: column;
    @include respond-to("xlarge") {
      flex-direction: row;
    }
  }
  &__menu {
    background-color: $white3;
    text-align: center;
    padding: 1rem 0;
    cursor: pointer;
    font-size: 1.3rem;
    color: $grey3;
    &:hover {
      background-color: $white2;
      font-weight: 700;
      color: $grey4;
    }
    @include respond-to("small") {
      font-size: 1.4rem;
    }
    @include respond-to("medium") {
      font-size: 1.6rem;
    }
    @include respond-to("large") {
      padding: 1rem 0;
      font-size: 1.7rem;
    }
    @include respond-to("xlarge") {
      padding: .8rem 0;
      font-size: 1.4rem;
      flex-basis: 20%;
    }
    @include respond-to("huge") {
      font-size: 1.7rem;
    }
    &.active {
      background-color: $white4;
      color: $grey4;
    }
  }
}
