.footer {
  color: $white1;
  background-color: #111111;
  padding: 2rem;
  position: relative;
  width: 100%;
  bottom: 0;
  @include respond-to("xlarge") {
    padding: 2rem 1rem;
  }
  &__logo {
    width: 35%;
    margin-bottom: 0.5rem;
    @include respond-to("small") {
      width: 100%;
    }
    @include respond-to("medium") {
      width: 70%;
      @include midItem;
    }
    @include respond-to("xlarge") {
      width: 50%;
      margin-bottom: 3rem;
    }
    @include respond-to("ultra") {
      width: 25%;
    }
  }
  &__link {
    font-size: 2rem;
    font-weight: 700;
    text-transform: capitalize;
    color: $white1;
    transition: all 0.5s ease;
    margin: 0.5rem 0;
    &:hover {
      font-weight: 400;
    }
    @include respond-to("small") {
      text-align: center;
      position: absolute;
      top: 30%;
      transform: translateY(-30%);
      width: 100%;
    }
    @include respond-to("xlarge") {
      font-size: 1.8rem;
      top: 35%;
      transform: translateY(-35%);
    }
  }
  &__socialbtns {
    margin: 1.5rem 0;
    @include respond-to("small") {
      text-align: center;
      margin: 0.5rem 0;
    }
    i {
      font-size: 3rem;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: translateY(-10%);
      }
      &:active {
        transform: translateY(0%) scale(0.98);
      }
      &:not(:last-child) {
        margin-right: 2rem;
        @include respond-to("xlarge") {
          margin-right: 0;
          margin: 0 1.6rem;
        }
      }
      @include respond-to("xlarge") {
        font-size: 2rem;
      }
    }
  }
  &__line {
    height: 2px;
    width: 40%;
    background-color: #fff;
    @include midItem;
    margin: 1.6rem 0;
    @include respond-to("small") {
      @include midItem;
      margin: 1.6rem auto;
    }
    @include respond-to("xlarge") {
      display: none;
    }
  }
  &__info {
    color: $white1;
    font-size: 1.4rem;
    margin: 0.8rem 0;
    @include respond-to("small") {
      text-align: center;
      font-size: 1.6rem;
      margin: 1rem 0;
    }
    @include respond-to("xlarge") {
      font-size: 1.2rem;
      margin-top: .5rem;
    }
    &Desktop {
      display: none;
      @include respond-to("xlarge") {
        display: inline;
      }
    }
    &Mobile {
      @include respond-to("xlarge") {
        display: none;
      }
    }
  }
}
