.btn {
  border: none;
  color: $white1;
  @include rounded(4px);
  transition: all 0.5s;
  &--orange {
    background-color: $orange3;
    &:hover {
      background-color: $orange2;
    }
    &:active {
      background-color: $white1 !important;
      color: $orange3 !important;
      @include shadow($orange2, 0.2);
    }
  }
  &--red {
    background-color: $red2;
    &:hover {
      background-color: $red1;
    }
    &:active {
      background-color: $white1 !important;
      color: $red2 !important;
      @include shadow($red3, 1);
    }
  }
  &--black {
    background-color: $grey2;
    &:hover {
      background-color: $grey1;
    }
    &:active {
      background-color: $white1 !important;
      color: $grey2 !important;
      @include shadow($grey3, 1);
    }
  }
  &--blue {
    background-color: $blue2;
    &:hover {
      background-color: $blue1;
    }
    &:active {
      background-color: $white1 !important;
      color: $blue2 !important;
      @include shadow($blue3, 1);
    }
  }
  &:focus {
    box-shadow: none !important;
  }
  &__icon {
    // background-color: yellow;
    text-align: center;
    width: 2.2rem;
  }
}
