.mediaHeadNewsCard {
  &__news {
    background-color: white;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    @include shadowIn($grey4, 0.85);
    @include rounded(15px);
    overflow: hidden;
  }
  &__content {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    @include respond-to("small") {
      padding: 1.5rem;
    }
    img {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
      margin-bottom: 0.6rem;
      @include respond-to("small") {
        transform: scale(1.2);
        margin-bottom: 0.8rem;
      }
      @include respond-to("xlarge") {
        transform: scale(1);
      }
      @include respond-to("huge") {
        transform: scale(1.3);
        margin-left: 0.4rem;
        margin-bottom: 1.3rem;
      }
    }
    .news {
      color: $white1;
      text-align: justify;
      font-size: 1.2rem;
      @include respond-to("small") {
        font-size: 1.6rem;
      }
      @include respond-to("medium") {
        font-size: 1.5rem;
      }
      @include respond-to("large") {
        font-size: 1.7rem;
      }
      @include respond-to("xlarge") {
        font-size: 1.2rem;
      }
      @include respond-to("huge") {
        font-size: 1.5rem;
      }
    }
  }
  &__check {
    opacity: 0;
  }
  &__link {
    color: $white1;
    font-size: 1.2rem;
    z-index: 1900;
    position: absolute;
    font-weight: 700;
    cursor: pointer;
    bottom: 1rem;
    right: 1.5rem;
    opacity: 0;
    transition: all 0.3s ease-out;
    @include respond-to("small") {
      font-size: 1.5rem;
      bottom: 1.3rem;
      right: 1.9rem;
    }
    @include respond-to("xlarge") {
      font-size: 1.2rem;
    }
    @include respond-to("huge") {
      font-size: 1.4rem;
    }
    &:hover {
      transform: scale(1.05);
      color: $white1;
    }
    &:active {
      transform: scale(1);
    }
  }
  &__label {
    background-color: blue;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 45%;
    opacity: 0;
  }
  &__overlay {
    background-color: rgba($grey4, 0.95);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $white1;
    padding: 1.5rem 1rem;
    transition: all 0.5s ease;
    top: -80%;
    opacity: 0;
    @include rounded(15px);
    .desc {
      text-align: justify;
      font-size: 1.2rem;
      text-indent: 1.5rem;
      height: 85%;
      overflow-y: scroll;
      @include respond-to("small") {
        font-size: 1.5rem;
        height: 80%;
      }
      @include respond-to("medium") {
        font-size: 1.4rem;
        height: 82%;
      }
      @include respond-to("large") {
        font-size: 1.5rem;
      }
      @include respond-to("xlarge") {
        font-size: 1.2rem;
      }
      @include respond-to("huge") {
        font-size: 1.5rem;
      }
    }
  }
}

input[type="checkbox"]:checked ~ .mediaHeadNewsCard__overlay {
    top: 0%;
    opacity: 1;
  }
  
  input[type="checkbox"]:checked ~ .mediaHeadNewsCard__link {
    opacity: 1;
  }
