.customInput {
  &__group {
    position: relative;
    margin-bottom: 1.5rem;
  }
  &__field {
    font-size: 1.4rem;
    color: $grey2;
    @include rounded(5px);
    padding: 2rem 3.2rem;
    border: 1px solid $orange2;
    position: relative;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border: none;
      border: 1px solid $orange3;
    }
  }
  &__icon {
    color: $grey2;
    @include centerV;
    font-size: 1.3rem;
    left: 1.2rem;
  }
}
