$regular: 400;
$bold: 700;

body {
  font-family: "Lato", sans-serif;
}

a {
  text-decoration: none !important;
}

p {
  margin: 0;
  padding: 0;
}

.fw {
  &--regular {
    font-weight: $regular;
  }
  &--bold {
    font-weight: $bold;
  }
}

.al {
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
  &--justify {
    text-align: justify;
  }
}

.tr {
  &--upper {
    text-transform: uppercase;
  }
  &--capital {
    text-transform: capitalize;
  }
}

.text {
  &--red {
    color: $red2 !important;
  }
  &--blue {
    color: $blue2 !important;
  }
  &--green {
    color: $green3 !important;
  }
  &--orange {
    color: $orange3 !important;
  }
  &--yellow {
    color: $yellow3 !important;
  }
  &--white {
    color: $white1 !important;
  }
}