$white1: #ffffff;
$white2: #f1f2f6;
$white3: #dfe4ea;
$white4: #ced6e0;
$grey1: #a4b0be;
$grey2: #747d8c;
$grey3: #57606f;
$grey4: #2f3542;
$red1: #ff7675;
$red2: #ff6b81;
$red3: #ff4757;
$blue1: #74b9ff;
$blue2: #54a0ff;
$blue3: #1e90ff;
$orange1: #feca57;
$orange2: #fd9644;
$orange3: #fa8231;
$yellow1: #fa983a;
$yellow2: #fbc531;
$yellow3: #e1b12c;
$green1: #1abc9c;
$green2: #2ecc71;
$green3: #16a085;
$pink1: #FDA7DF;
$pink2: #D980FA;
$pink3: #9980FA;